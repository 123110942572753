import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Col, Row } from 'antd';


const items = [
  {
    label: (
      <Link to="/" refresh="true">
        <h4 className='text'>Home</h4>
      </Link>
    ),
    key: 'home',
  },
  {
    label: (
      <Link to="/conferences">
        <h4 className='text'>Conferences</h4>
      </Link>
    ),
    key: 'confs',
  },
  {
    label: (
      <Link to="/society-info" refresh="true">
        <h4 className='text'>Society Info</h4>
      </Link>
    ),
    key: 'about',
    children: [
      {
        label: (
          <Link to="/executive-and-international-representatives" refresh="true">
            <h4 className='text'>Executive and International Representatives</h4>
          </Link>
        ),
        key: 'eir',
      },
      {
        label: (
          <Link to="/past-pps-officers" refresh="true">
            <h4 className='text'>Past PPS Officers</h4>
          </Link>
        ),
        key: 'ppo',
      },
    ],
  },
  {
    label: (
      <Link to="/membership" refresh="true">
        <h4 className='text'>Membership</h4>
      </Link>
    ),
    key: 'membership',
  },
  {
    label: (
      <Link to="/awards">
        <h4 className='text'>Awards</h4>
      </Link>
    ),
    key: 'awards',
    children: [
      {
        label: (
          <Link to="/awards/james-l-white-innovation-award" refresh="true">
            <h4 className='text'>James L. White Innovation Award</h4>
          </Link>
        ),
        key: 'jlwia',
      },
      {
        label: (
          <Link to="/awards/morand-lambla-award" refresh="true">
            <h4 className='text'>Morand Lambla Award</h4>
          </Link>
        ),
        key: 'mla',
      },
      {
        label: (
          <Link to="/awards/pps-early-career-award" refresh="true">
            <h4 className='text'>PPS Early Career Award</h4>
          </Link>
        ),
        key: 'peca',
      },
      {
        label: (
          <Link to="/awards/pps-fellows" refresh="true">
            <h4 className='text'>PPS Fellows</h4>
          </Link>
        ),
        key: 'ppf',
      },
      {
        label: (
          <Link to="/awards/pps-researcher-travel-award" refresh="true">
            <h4 className='text'>PPS Researcher Travel Award</h4>
          </Link>
        ),
        key: 'pyrta',
      },
    ],
  },
  {
    label: (
      <Link to="/publications">
        <h4 className='text'>Publications</h4>
      </Link>
    ),
    key: 'publications',
  },
  {
    label: (
      <Link to="/newsletter">
        <h4 className='text'>Newsletter</h4>
      </Link>
    ),
    key: 'newsletter',
  },
  {
    label: (
      <Link to="/downloads">
        <h4 className='text'>Downloads</h4>
      </Link>
    ),
    key: 'downloads',
  },
  {
    label: (
      <Link to="/contact-us">
        <h4 className='text'>Contact Us</h4>
      </Link>
    ),
    key: 'contact',
  },
  // {
  //   label: (
  //     <Link to="/">
  //       <h4 className='text'>Join/Login</h4>
  //     </Link>
  //   ),
  //   key: 'account',
  // },

  // {
  //   label: 'Navigation Two',
  //   key: 'app',
  //   icon: <AppstoreOutlined />,
  //   disabled: true,
  // },
  // {
  //   label: 'Navigation Three - Submenu',
  //   key: 'SubMenu',
  //   icon: <SettingOutlined />,
  //   children: [
  //     {
  //       type: 'group',
  //       label: 'Item 1',
  //       children: [
  //         {
  //           label: 'Option 1',
  //           key: 'setting:1',
  //         },
  //         {
  //           label: 'Option 2',
  //           key: 'setting:2',
  //         },
  //       ],
  //     },
  //     {
  //       type: 'group',
  //       label: 'Item 2',
  //       children: [
  //         {
  //           label: 'Option 3',
  //           key: 'setting:3',
  //         },
  //         {
  //           label: 'Option 4',
  //           key: 'setting:4',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   label: (
  //     <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
  //       Navigation Four - Link
  //     </a>
  //   ),
  //   key: 'alipay',
  // },
];

const Header = () => {
  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    // console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <div>
      <div className='page-container'>
        <Row gutter={5} className='pt-[5px] pb-[10px]'>
          <Col xs={16} md={6}>
            <img src={require(`../assets/logo.png`)} width="300" alt='Polymer Processing Society Logo' />
          </Col>
          <Col xs={8} md={18}>
            <Menu className='justify-end' onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
          </Col>
        </Row>
        {/* <div className='flex justify-end'> */}
        {/* </div> */}
      </div>
    </div>
  )
}

export default Header