import { React, useState } from 'react';
import "./components.css"
import { Carousel } from 'antd';
import placeholderImg from '../assets/slider_placeholder.png';


const Slider = ({ images }) => {

  const [loaded, setLoaded] = useState(false);

  // console.log(images.slider_description_homepage)
  // console.log(descriptions)

  return (
    <Carousel autoplay dots arrows>
      {images.map(img => (
        <div key={img} className='max-h-[600px]'>

          <img
            // src={img}
            src={loaded ? img.slider_image_homepage : placeholderImg}
            onLoad={() => setLoaded(true)}
            alt="Carousel image"
            className='slider-image-height'
            style={{ width: '100%', objectFit: 'cover', objectPosition: 'center center' }}
          />

          {
            (img.slider_description_homepage === "") ?
              null
              :
              <p className='z-50 text-center text-[16px] bg-black text-white p-[3px]'>
                {img.slider_description_homepage}
              </p>
          }




        </div>
      ))}
    </Carousel>
  );
}

export default Slider;