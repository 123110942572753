import { React, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// import LazyImage from 'react-image';
// import { Placeholder } from 'react-placeholder';
import "./components.css";
import placeholderImg from '../assets/profile_placeholder.jpg';
// import noProfile from 'https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/no_profile.png';

const AwardeeCard = ({ name, year, slug, imagepath }) => {
    const [loaded, setLoaded] = useState(false);
    const noProfile = '../assets/no_profile.png'
    // console.log(imagepath);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling animation
        });
    }

    return (
        <div className='flex flex-col items-center mx-[5px] mt-0 mb-[20px]'>
            <div>
                {
                    // (imagepath === false) ?
                    //     // <img src="https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/no_profile.png" className='awardee_image' /> 
                    //     <img
                    //         src={loaded ? noProfile : placeholderImg}
                    //         onLoad={() => setLoaded(true)}
                    //         alt="awardee_image"
                    //         className='awardee_image'
                    //     />
                    //     :
                    //     // <img src={imagepath.sizes.medium} className='awardee_image' />
                    //     <img
                    //         src={loaded ? imagepath.sizes.medium : placeholderImg}
                    //         onLoad={() => setLoaded(true)}
                    //         alt="awardee_image"
                    //         className='awardee_image'
                    //     />

                    imagepath ?
                        <>
                            <img
                                src={loaded ? imagepath.sizes.medium : placeholderImg}
                                onLoad={() => setLoaded(true)}
                                alt="awardee_image"
                                className='awardee_image'
                            />
                        </>
                        :
                        <>
                            <img
                                src={require(`../assets/no_profile.png`)}
                                onLoad={() => setLoaded(true)}
                                alt="awardee_image"
                                className='awardee_image'
                            />
                        </>
                }

            </div>
            {/* <img src={imagepath} width="250" /> */}
            <p className="text-center text">{name}</p>
            <p className="text-center text font-bold">{year}</p>

            <Link to={`/awardees/${slug}`}>
                <button onClick={scrollToTop} className='rounded-[3px] bg-[#5C469C] px-[5px] py-[2px] flex'>
                    <p className='text-small-inverse'>Details</p>
                </button>
            </Link>
        </div>
    )
}

export default AwardeeCard