import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";

import Loader from '../components/Loader';
import LargeHeading from '../components/LargeHeading';
import GutenbergBlock from '../components/GutenbergBlock';


const PageTemplate = () => {
  const { pageSlug } = useParams(); // Access the parameter as 'pageSlug'
  const [pageData, setPageData] = useState(null);
  const [pageHeading, setPageHeading] = useState(null);
  const [loader, setLoader] = useState(true);


  useEffect(() => {
    setLoader(true);
    // console.log("new page data loaded")
    axios.get(`wp/v2/pages?slug=${pageSlug}`)
      .then((response) => {
        // console.log(response.data[0].content.rendered);
        setPageHeading(response.data[0].title.rendered);
        setPageData(response.data[0].content.rendered);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [pageSlug])

  return (
    <>
      {
        (loader === true) ? <Loader /> :
          <>
            <div>
              <Helmet>
                <title>{pageHeading} - Polymer Processing Society</title>
              </Helmet>
            </div>
            <div className='outer-section pb-[25px]'>
              <div className='inner-section text-center'>
                <LargeHeading title={pageHeading} />
              </div>

              <div className='inner-section'>
                <GutenbergBlock content={pageData} />
              </div>
            </div>
          </>
      }
    </>
  )
}

export default PageTemplate