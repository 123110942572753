import React from 'react'
import "./components.css"

const MediumHeading = ({ title }) => {
    return (
        <>
            <h1 className='underline-heading heading-medium'>{title}</h1>
            <div className='underline self-center'></div>
        </>
    )
}

export default MediumHeading