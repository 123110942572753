import React, { useState, useEffect } from 'react'
// import { Col, Row } from 'antd';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import LargeHeading from '../components/LargeHeading'
import MediumHeading from '../components/MediumHeading'
import TextBlock from '../components/TextBlock'
import Loader from '../components/Loader';

import '../components/components.css';
import '../components/text.css'
import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";

const SocietyInfo = () => {
    const [aboutinfo, setAboutinfo] = useState({});
    const [loader, setLoader] = useState(true);
    const aboutInfo = () => {
        axios.get(`wp/v2/pages/79?acf_format=standard`)
            .then((response) => {
                // console.log(response.data.acf);
                setAboutinfo(response.data.acf);
                setLoader(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        setLoader(true);
        aboutInfo();
    }, [])

    return (
        <>
            {
                loader ? <Loader />
                    :
                    <>
                        <div>
                            <Helmet>
                                <title>About - Polymer Processing Society</title>
                            </Helmet>
                        </div>
                        <div className='page-container'>

                            {/* about */}
                            <div className='outer-section text-center'>
                                <LargeHeading title="About Polymer Processing Society" />
                            </div>
                            <div className='inner-section'>
                                {/* <TextBlock content="The Polymer Processing Society was founded in March 1985 at the University of Akron, Ohio, USA. The intent was to provide a mechanism and format for interaction and presentation of research results in the international polymer processing community. " /> */}
                                <TextBlock content={aboutinfo.about_pps_about_info} />
                            </div>

                            {/* goals */}
                            <div className='outer-section text-center'>
                                <MediumHeading title="Our Goals" />
                            </div>
                            <div className='inner-section'>
                                {/* <TextBlock content="The goals of the Polymer Processing Society (PPS) as embodied in its constitution are to foster scientific understanding and technical innovation in polymer processing by providing a discussion forum for the worldwide community of engineers and scientists in the field. The thematic range of the PPS encompasses all formulation, conversion and shaping operations applied to polymeric systems in the transformation from their monomeric forms to commercial products." /> */}
                                <TextBlock content={aboutinfo.pps_goals_about_info} />
                            </div>

                            {/* membership */}
                            <div className='outer-section text-center'>
                                <MediumHeading title="Membership" />
                            </div>
                            <div className='inner-section'>
                                {/* <TextBlock content="Membership in PPS is open to all researchers in the field, and to all persons who feel the activities of the society advance their professional development." /> */}
                                <TextBlock content={aboutinfo.pps_membership_about_info} />
                            </div>

                            {/* constitution */}
                            <div className='outer-section text-center'>
                                <MediumHeading title="PPS Constitution" />
                            </div>
                            <div className='inner-section'>
                                {/* <TextBlock content="The goals of the Polymer Processing Society (PPS) as embodied in its constitution are to foster scientific understanding and technical innovation in polymer processing by providing a discussion forum for the worldwide community of engineers and scientists in the field. The thematic range of the PPS encompasses all formulation, conversion and shaping operations applied to polymeric systems in the transformation from their monomeric forms to commercial products." /> */}
                                <TextBlock content={aboutinfo.pps_constitution_about_info} />
                            </div>

                            {/* resources */}
                            <div className='outer-section text-center'>
                                <MediumHeading title="PPS Resources" />
                            </div>
                            <div className='inner-section'>
                                {/* <TextBlock content="The goals of the Polymer Processing Society (PPS) as embodied in its constitution are to foster scientific understanding and technical innovation in polymer processing by providing a discussion forum for the worldwide community of engineers and scientists in the field. The thematic range of the PPS encompasses all formulation, conversion and shaping operations applied to polymeric systems in the transformation from their monomeric forms to commercial products." /> */}
                                <figure class="wp-block-table html_text_block">
                                    <table class="has-fixed-layout">
                                        <thead>
                                            <tr>
                                                <th class="has-text-align-left" data-align="left">Title</th>
                                                <th class="has-text-align-left" data-align="left">Modified Date</th>
                                                <th class="has-text-align-left" data-align="left">Size</th>
                                                <th class="has-text-align-left" data-align="left">Attachment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aboutinfo.pps_resources_about_info.map((resource) => (
                                                <tr key={resource.title_pps_resources_about_info}>
                                                    <td class="has-text-align-left text font-bold" data-align="left">{resource.title_pps_resources_about_info}</td>
                                                    <td class="has-text-align-left text" data-align="left">{resource.modified_date_pps_resources_about_info}</td>
                                                    <td class="has-text-align-left text" data-align="left">{resource.size_pps_resources_about_info}</td>
                                                    <td class="has-text-align-left text" data-align="left">
                                                        <a href={resource.attachment_pps_resources_about_info}>Download</a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </figure>
                            </div>
                        </div>
                    </>
            }
        </>



    )
}

export default SocietyInfo