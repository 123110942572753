import React from 'react'

const NoPage = () => {
    return (
        <div className='page-container p-[80px]'>
            <h1 className='text-family text-center text-red-600 font-bold text-[100px]'>404</h1>
            <p className='text-family text-[30px] text-bold text-center'>Page Not Found</p>
        </div>
    )
}

export default NoPage