import React from 'react'
import { Col, Row } from 'antd';

import "./components.css"

const InfoCardSecondary = ({ content, imagepath }) => {
    return (
        <div className='info-card info-card-secondary'>
            <Row>
                <p className='text min-h-[100px]'>{content}</p>
            </Row>
            <div className='spacer-large'></div>
            <Row>
                <Col span={14}>
                    <h3 className="read-more">READ MORE</h3>
                </Col>
                <Col span={10}>
                    <img src={require(`../assets/${imagepath}`)} width="75" className='float-right' alt='Vision Statement' />
                </Col>
            </Row>
        </div>
    )
}

export default InfoCardSecondary