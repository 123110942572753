import React from 'react'
import "./components.css"
import { Link } from 'react-router-dom';

const EventTableRow = ({ description, date, url }) => {
    return (

        <div className="event-table-content flex">
            <div class="w-[40%]">
                <h2 className='text py-[8px] px-[15px]'>{description}</h2>
            </div>
            <div class="w-[40%]">
                <h2 className='text py-[8px] px-[15px]'>{date}</h2>
            </div>
            <div class="w-[19%]">
                <a href={url} target="_blank" className='special-link'>
                    <h2 className='text py-[8px] px-[15px]'>Details <>&#x1F517;</></h2>
                </a>
            </div>
        </div>
    )
}

export default EventTableRow