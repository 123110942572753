import React from 'react'
import "./components.css"
import { Link } from "react-router-dom";


const AwardSingle = ({ awardname, slug }) => {
  return (
    <>
      <Link to={`/awards/${slug}`}>
        <div className='award-single h-[200px] hover:bg-[#8eac50]'>
          <p className="heading-medium-inverse">{awardname}</p>
          <p className='text-small-inverse'>LEARN MORE</p>
        </div>
      </Link>
    </>
  )
}

export default AwardSingle