import React, { useEffect, useState } from 'react'
import ButtonSmall from './ButtonSmall'
import AwardeeCard from './AwardeeCard'

const DownloadsItem = ({ title, author, link }) => {
    // console.log(author)
    // console.log(link)

    const [links, setLinks] = useState([])
    const [authors, setAuthors] = useState([])


    useEffect(() => {
        setLinks(link)
        setAuthors(author)
    }, [])

    return (
        <div className='p-[10px] border-grey border-[1px] rounded'>
            <h2 className='heading-small mb-[8px]'>{title}</h2>
            <div className='flex flex-row gap-3 mb-[10px]'>
                <p className='text'>Resource Links :</p>
                {
                    links.map(item => (
                        <ButtonSmall key={item.resource_button_title_repeater_downloads} title={item.resource_button_title_repeater_downloads} url={item.resource_url_repeater_downloads} />
                    ))
                }
            </div>
            <div>
                <p className='mb-[5px] text'>Author : </p>
                <div className='flex flex-row gap-5'>
                    {
                        authors.map(item => (
                            // <AwardeeCard name={item.by_name_repeater_downloads} imagepath={item.by_image_repeater_downloads} year={""} slug={""} />

                            <div key={item.by_name_repeater_downloads} className='flex flex-row gap-5 w-full items-center'>
                                <img src={item.by_image_repeater_downloads} alt="author_image" style={{ height: 100 }} />
                                <p className='text'>{item.by_name_repeater_downloads}</p>
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default DownloadsItem