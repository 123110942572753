import React, { useEffect, useState } from 'react'
// import _ from 'lodash'
import { Col, Row, Spin } from 'antd';

import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import LargeHeading from './../components/LargeHeading';
import AwardeeCard from '../components/AwardeeCard';
import ButtonSmall from '../components/ButtonSmall';
import Loader from '../components/Loader';
import Awardee from './Awardee';
import DownloadsItem from '../components/DownloadsItem';

const Downloads = () => {
    const [downloaditems, setDownloaditems] = useState([])
    const [filtereditems, setFiltereditems] = useState([])
    const [downloadsloader, setDownloadsloader] = useState(true);
    const [categories, setCategories] = useState({})
    const [activebutton, setActivebutton] = useState(null)


    useEffect(() => {
        fetchDownloadsdata();
        fetchCatgories()
    }, [])

    const fetchDownloadsdata = () => {
        axios.get('wp/v2/pps_downloads?acf_format=standard&per_page=99')
            .then((response) => {
                // console.log(response.data);
                // if (response.data === undefined) { console.log("skip") }
                // else { console.log(response.data) }
                setDownloaditems(response.data);
                setFiltereditems(response.data);
                setDownloadsloader(false)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const fetchCatgories = () => {
        axios.get('wp/v2/download_categories?acf_format=standard&per_page=99')
            .then((response) => {
                // console.log(response.data);
                setCategories(response.data)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const resetCategory = () => {
        setActivebutton(null);
        setFiltereditems(downloaditems)
    }

    const filterbyCategory = (value) => {
        // console.log(value)
        // console.log(downloaditems)
        const filtered = downloaditems.filter(item => item.download_categories[0] === value)
        // const filtered = _.filter(downloaditems, { id: value });
        setFiltereditems(filtered)
        setActivebutton(value)
    }

    return (
        <div>
            {
                downloadsloader ? <Loader /> :
                    <>
                        <div>
                            <Helmet>
                                <title>Downloads - Polymer Processing Society</title>
                            </Helmet>
                        </div>
                        <div className='page-container'>
                            <div className='outer-section text-center'>
                                <LargeHeading title="Downloads" />
                            </div>
                            <div className='inner-section'>
                                {/* download categories tab */}
                                {/* <div className='flex flex-row gap-3 mb-[15px]'> */}
                                {
                                    (categories.length === 0) ?
                                        <Spin />
                                        :
                                        <div className='grid gap-4 mb-[20px] grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7'>
                                            {
                                                categories.map(item => (
                                                    <button
                                                        className={(item.id === activebutton) ?
                                                            'px-3 py-1 border-solid border-[1px] border-gray-100 rounded brand-bg text-inverse'
                                                            :
                                                            'px-3 py-1 border-solid border-[1px] border-gray-100 rounded text'}
                                                        value={item.id}
                                                        onClick={() => filterbyCategory(item.id)}
                                                    >
                                                        <p>{item.name}</p>
                                                    </button>
                                                ))
                                            }
                                            {
                                                (activebutton === null) ? null
                                                    :
                                                    <button onClick={() => resetCategory()} className='px-3 py-1 border-solid border-[1px] border-red-400 rounded text'>Show All</button>

                                            }
                                        </div>
                                }
                                {/* download list */}
                                <>
                                    {(filtereditems.length === 0) ?
                                        <p className='text-center text mt-[50px]'>No items found</p>
                                        :
                                        <>
                                            {
                                                filtereditems.map(item => (
                                                    // <p>hi</p>
                                                    <div className='mb-[15px]'>
                                                        <DownloadsItem key={item.id} title={item.title.rendered} author={item.acf.author_downloads} link={item.acf.resource_link_downloads} />
                                                    </div>
                                                ))
                                            }
                                        </>
                                    }
                                </>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

export default Downloads