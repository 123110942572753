import { useParams } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';

import Loader from '../components/Loader';
import LargeHeading from '../components/LargeHeading';
import TextBlock from '../components/TextBlock';
import GutenbergBlock from '../components/GutenbergBlock'
import ButtonBig from '../components/ButtonBig';
import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";

const AwardTemplate = () => {
    const { awardSlug } = useParams(); // Access the parameter as 'pageSlug'
    const [awardData, setAwardData] = useState({});
    const [awardHeading, setAwardHeading] = useState({});
    const [awardeebutton, setAwardeebutton] = useState(true);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
        // console.log("new page data loaded")
        axios.get(`wp/v2/awards?slug=${awardSlug}&acf_format=standard`)
            .then((response) => {
                // console.log(response.data[0]);
                setAwardHeading(response.data[0].name);
                setAwardData(response.data[0].acf.award_details.post_content);
                setAwardeebutton(response.data[0].acf.has_awardees);
                setLoader(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [awardSlug])

    return (
        <>
            {
                (loader === true) ? <Loader /> :
                    <>
                        <div>
                            <Helmet>
                                <title>{awardHeading} - Polymer Processing Society</title>
                            </Helmet>
                        </div>
                        <div className='outer-section pb-[25px]'>
                            <div className='inner-section text-center'>
                                <LargeHeading title={awardHeading} />
                            </div>

                            {
                                awardeebutton ?
                                    <>
                                        <div className='inner-section flex justify-center'>
                                            <ButtonBig title="View Awardees" url="awardees" />
                                        </div>
                                    </> : null
                            }



                            <div className='inner-section'>
                                <GutenbergBlock content={awardData} />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default AwardTemplate