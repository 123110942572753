import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';

import { Col, Row } from 'antd';
import NewsletterSingle from '../components/NewsletterSingle'
import NewsletterSingle2 from '../components/NewsletterSingle2'
import { Button, Modal } from 'antd';
import LargeHeading from '../components/LargeHeading';
import Loader from '../components/Loader';

const NewsLetter = () => {
    const [visible, setVisible] = useState(false);
    const [activeYear, setActiveYear] = useState(2023);
    const [years, setYears] = useState([]);
    const [newsletters, setNewsletters] = useState([]);
    const [modaldata, setModaldata] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        axios.get('/wp/v2/newsletter?per_page=99&acf_format=standard')
            .then(res => {
                const years = res.data.map(item => item.title.rendered);

                const newslettersData = res.data.map(item => {
                    return {
                        year: item.title.rendered,
                        newsletters: item.acf.newsletter
                    }
                });
                // console.log(years);
                // console.log(newslettersData);

                setYears(years);
                setNewsletters(newslettersData);
                setLoader(false);
            })
    }, []);



    const handleClick = (ayear) => {
        // console.log('Active year: ', ayear);
        // console.log(activeYear);
        // const year = activeYear;
        // console.log(newsletters);

        let currentNewsletters = [];
        currentNewsletters = newsletters.find(item => item.year === ayear).newsletters;
        // console.log('Newsletters: ', currentNewsletters);

        setModaldata(currentNewsletters);
        setVisible(true);
    }

    return (
        <>
            {
                loader ?
                    <Loader /> :
                    <>
                        <div>
                            <Helmet>
                                <title>PPS Newsletter - Polymer Processing Society</title>
                            </Helmet>
                        </div>
                        <div className="outer-section">
                            <div className="text-center">
                                <LargeHeading title="PPS Newsletter" align="text-center" />
                            </div>

                            <div className="inner-section">
                                <Row gutter={10}>
                                    {years.map(year => (
                                        <Col xs={12} md={4}>
                                            <NewsletterSingle2 year={year}
                                                onClick={() => {
                                                    // e.stopPropagation();
                                                    // setVisible(true);
                                                    // setActiveYear(year);
                                                    handleClick(year);
                                                }}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                            {visible && (
                                <Modal
                                    visible
                                    footer={null}
                                    onCancel={() => setVisible(false)}
                                >
                                    <>
                                        <div className="mt-[30px] mb-[30px]">
                                            <Row gutter={10}>
                                                {modaldata.map(newsletter => (
                                                    <Col xs={24} md={12}>
                                                        <NewsletterSingle title={newsletter.title} url={newsletter.file} key={modaldata.title} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </>

                                </Modal>
                            )}
                        </div>
                    </>
            }
        </>
    )
}

export default NewsLetter