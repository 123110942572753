import React from 'react'
import { Col, Row } from 'antd';

import "./components.css"

const InfoCardPrimary = ({ content, imagepath }) => {
    return (
        <div className='info-card info-card-primary md-mx-[10px]'>
            <Row>
                <p className='text-inverse min-h-[100px]'>{content}</p>
            </Row>
            <div className='spacer-large'></div>
            <Row>
                <Col span={14} align="bottom">
                    <h3 className="read-more text-white">READ MORE</h3>
                </Col>
                <Col span={10}>
                    {/* <div className="flex mx-auto"> */}
                        <img src={require(`../assets/${imagepath}`)} width="75" className='float-right' alt='Mission Statement' />
                    {/* </div> */}
                </Col>
            </Row>
        </div>
    )
}

export default InfoCardPrimary