import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


const ButtonSmall = ({ title, url }) => {
    return (
        <div>
            <Link to={url}>
                <button className='rounded-[3px] bg-[#5C469C] px-[5px] py-[2px] flex'>
                    <p className='text-small-inverse'>{title}</p>
                </button>
            </Link>
        </div >
    )
}

export default ButtonSmall