import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


const ButtonBig = ({ title, url }) => {
    return (
        <div>
            <Link to={url}>
                <button className='rounded-[3px] bg-[#5C469C] px-[15px] py-[10px] flex'>
                    <p className='text-inverse'>{title}</p>
                </button>
            </Link>
        </div >
    )
}

export default ButtonBig