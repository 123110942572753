import React, { useState, useEffect } from 'react'
import { Col, Row } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';

import "./pages.css"
import Slider from "../components/Slider"
import MediumHeading from '../components/MediumHeading'
// import LargeHeading from '../components/LargeHeading'
import EventTableHeader from '../components/EventTableHeader'
import EventTableRow from '../components/EventTableRow'
import InfoCardPrimary from '../components/InfoCardPrimary';
import InfoCardSecondary from '../components/InfoCardSecondary';
import NewsletterSingle from '../components/NewsletterSingle';
import AwardSingle from '../components/AwardSingle';
import TextBlock from '../components/TextBlock';
import Loader from '../components/Loader';




const HomePage = () => {
    const [homeloader, setHomeloader] = useState(true);
    const [awardloader, setAwardloader] = useState(true);
    const [newsletterloader, setNewsletterloader] = useState(true);
    const [sliderimages, setSliderimages] = useState([]);
    const [sliderdescription, setSliderdescription] = useState([]);
    const [internationalconf, setInternationalconf] = useState([]);
    const [regionalconf, setRegionalconf] = useState([]);
    const [webinar, setWebinar] = useState([]);
    const [content, setContent] = useState({});
    const [awards, setAwards] = useState([]);
    const [newsletters, setNewsletters] = useState([]);
    const [toggles, setToggles] = useState({});

    useEffect(() => { console.log(content) }, [content]);

    const homepageData = () => {
        axios.get(`wp/v2/pages/39?acf_format=standard`)
            .then((response) => {

                // debug response with console.log
                console.log(response.data.acf);

                const sliders = response.data.acf.slider_images_repeater_homepage;
                setSliderimages(sliders)
                // const slider_images = sliders.map(item => item.slider_image_homepage, item => item.slider_description_homepage);
                // setSliderimages(slider_images);
                // const slider_description = sliders.map(item => item.slider_description_homepage)
                // setSliderdescription(slider_description);

                setInternationalconf(response.data.acf.upcoming_international_conference_homepage);
                setRegionalconf(response.data.acf.upcoming_regional_conference_homepage);
                setWebinar(response.data.acf.upcoming_webniars_homepage);
                setToggles({
                    international: response.data.acf.show_international_conferences_section_homepage,
                    regional: response.data.acf.show_regional_conferences_section_homepage,
                    webinar: response.data.acf.show_webinars_section_homepage
                })
                setContent({
                    mission: response.data.acf.mission_card_content_homepage,
                    goals: response.data.acf.our_goals_card_content_homepage,
                    about: response.data.acf.about_society_content_homepage,
                    educational: response.data.acf.free_educational_downloads_homepage,
                    proceedings: response.data.acf.conference_proceedings_homepage
                });
                setHomeloader(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const awardsData = () => {
        axios.get('wp/v2/awards')
            .then((response) => {
                // console.log(response.data);
                let awardsInfo = [];

                response.data.forEach(award => {
                    let awardInfo = {
                        name: award.name,
                        description: award.acf.description_awards,
                        slug: award.slug,
                    };

                    awardsInfo.push(awardInfo);
                });

                // console.log(awardsInfo);
                setAwards(awardsInfo);
                setAwardloader(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const newsletterData = () => {
        axios.get('wp/v2/newsletter?acf_format=standard&per_page=4')
            .then((response) => {
                const originalData = response.data;

                // Create filtered array
                const newsletters = [];

                originalData.forEach(item => {
                    const { newsletter } = item.acf;

                    newsletter.forEach(nl => {
                        newsletters.push(nl);
                    });
                });

                // console.log(newsletters.slice(0, 6));
                setNewsletters(newsletters.slice(0, 6));
                setNewsletterloader(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        homepageData();
        awardsData();
        newsletterData();
    }, [])



    return (
        <>
            {
                (homeloader === true && awardloader === true && newsletterloader === true) ? <Loader /> :
                    <>

                        {/* top slider */}
                        <Slider images={sliderimages} />

                        {/* about society */}
                        <div className="outer-section">
                            <div className="text-center">
                                <MediumHeading title="About The Society" align="text-center" />
                            </div>
                            <div className="inner-section">
                                <TextBlock content={content.about} />
                            </div>
                        </div>

                        {/* conference schedule */}
                        <>
                            {
                                (toggles.international === false && toggles.regional === false && toggles.webinar === false) ? null :
                                    <>
                                        <div className="outer-section">
                                            <div className="text-center">
                                                <MediumHeading title="Upcoming Conferences" align="text-center" />
                                            </div>

                                            {/* upcoming conferences table */}
                                            <>
                                                {
                                                    toggles.international ?
                                                        <>
                                                            <div className="inner-section">
                                                                <EventTableHeader heading="International Conferences" />
                                                                {internationalconf.map(conference =>
                                                                    <EventTableRow key={conference.description_international_conference_homepage} description={conference.description_international_conference_homepage} date={conference.date_international_conference_homepage} url={conference.url_international_conference_homepage} />
                                                                )}
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </>

                                            <>
                                                {
                                                    toggles.regional ?
                                                        <>
                                                            <div className="inner-section">
                                                                <EventTableHeader heading="Regional Conferences" />
                                                                {regionalconf.map(conference =>
                                                                    <EventTableRow key={conference.description_regional_conference_homepage} description={conference.description_regional_conference_homepage} date={conference.date_regional_conference_homepage} url={conference.url_regional_conference_homepage} />
                                                                )}
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </>

                                            <>
                                                {
                                                    toggles.webinar ?
                                                        <>
                                                            <div className="inner-section">
                                                                <EventTableHeader heading="Webinars" />
                                                                {webinar.map(conference =>
                                                                    <EventTableRow key={conference.description_webniars_homepage} description={conference.description_webniars_homepage} date={conference.date_webniars_homepage} url={conference.url_webniars_homepage} />
                                                                )}
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </>

                                        </div>
                                    </>
                            }
                        </>

                        {/* conference proceeding   */}
                        <>
                            <div className="inner-section">
                                <EventTableHeader heading="Conference Proceedings" />
                                <div classname='border-solid border-[2px] border-black'>
                                    <p className='text p-4 mb-[-60px]'>
                                        <TextBlock content={content.proceedings} />
                                    </p>
                                </div>
                            </div>
                        </>

                        {/* educational content */}
                        <>
                            <div className="inner-section">
                                <EventTableHeader heading="Free Educational Downloads" />
                                <div classname='border-solid border-[2px] border-black'>
                                    <p className='text p-4 mb-[-60px]'>
                                        <TextBlock content={content.educational} />
                                    </p>
                                    <Link to='/downloads'>
                                        <p className='px-4 text-[18px] font-bold uppercase brand-font-one'>Read More</p>
                                    </Link>
                                </div>
                            </div>
                        </>

                        {/* Mission-Vision */}
                        <div className="outer-section">
                            <Row gutter={10}>
                                <Col xs={24} md={12}>
                                    <InfoCardPrimary content={content.mission} imagepath="mission.png" />
                                </Col>

                                <Col xs={24} md={12}>
                                    <InfoCardSecondary content={content.goals} imagepath="goals.png" />
                                </Col>
                            </Row>
                        </div>

                        {/* Latest Newsletters - show last published 4 */}
                        <div className="outer-section">
                            <div className="text-center">
                                <MediumHeading title="Latest Newsletter" align="text-center" />
                            </div>
                            <div className="inner-section">
                                <Row gutter={10}>

                                    {newsletters.map(newsletter => (
                                        <Col xs={12} md={8} xl={4}>
                                            <NewsletterSingle title={newsletter.title} url={newsletter.file} key={newsletter.title} />
                                        </Col>
                                    ))}

                                    {/* <Col xs={12} md={4}>
                                        <NewsletterSingle title="05-2023" />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <NewsletterSingle title="11-2022" />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <NewsletterSingle title="05-2022" />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <NewsletterSingle title="11-2021" />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <NewsletterSingle title="05-2021" />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <NewsletterSingle title="11-2020" />
                                    </Col> */}
                                </Row>
                            </div>
                        </div>

                        {/* awards */}
                        <div className="outer-section">
                            <div className="text-center">
                                <MediumHeading title="PPS Awards" align="text-center" />
                            </div>
                            <div className="inner-section">
                                <div className="flex flex-wrap special-margin">
                                    {awards.map(awards =>
                                        <div className='w-1/2 md:w-1/3 lg:w-1/5'>
                                            <AwardSingle key={awards.name} awardname={awards.name} slug={awards.slug} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default HomePage