import React from 'react'
import './components.css'
// import './text.css'
import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";

const TextBlock = ({ content }) => {
  return (
    <div className="text html_text_block" dangerouslySetInnerHTML={{__html: content}}/>
  )
}

export default TextBlock