import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Link } from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet';


import LargeHeading from '../components/LargeHeading';
import AwardSingle from '../components/AwardSingle';
import TextBlock from '../components/TextBlock';
import Loader from '../components/Loader';


const Awards = () => {
    const [loader, setLoader] = useState(true);
    const [awards, setAwards] = useState([]);

    const awardsData = () => {
        axios.get('wp/v2/awards')
            .then((response) => {
                // console.log(response.data);
                let awardsInfo = [];

                response.data.forEach(award => {
                    let awardInfo = {
                        name: award.name,
                        description: award.acf.description_awards,
                        slug: award.slug,
                    };

                    awardsInfo.push(awardInfo);
                });

                // console.log(awardsInfo);
                setAwards(awardsInfo);
                setLoader(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        awardsData();
    }, [])

    return (
        <>
            {
                (loader === true) ? <Loader /> :
                    <>
                        <div>
                            <Helmet>
                                <title>Awards - Polymer Processing Society</title>
                            </Helmet>
                        </div>
                        <div className='page-container'>
                            <div className='outer-section'>
                                <div className='text-center'>
                                    <LargeHeading title="Awards" align="text-center" />
                                </div>

                                <div className='inner-section'>
                                    <div className="flex flex-wrap special-margin">
                                        {awards.map(awards =>
                                            <div className='w-1/2 md:w-1/3 lg:w-1/5'>
                                                <AwardSingle key={awards.name} awardname={awards.name} slug={awards.slug} />
                                            </div>
                                        )}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default Awards