import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/Loader';
import LargeHeading from '../components/LargeHeading';
import AwardeeCard from '../components/AwardeeCard';
import AllAwardeeCard from '../components/AllAwardeeCard';

const AllAwardees = () => {
    const [allawardeedetails, setAllawardeedetails] = useState([])
    const [loader, setLoader] = useState(true);


    useEffect(() => {
        setLoader(true);
        console.log("dataload");
        axios.get(`wp/v2/awardees?per_page=99&acf_format=standard`)
            .then((response) => {
                console.log(response.data[0].awards[0]);
                const awardeedataloader = response.data.map(item => {
                    return {
                        name: item.title.rendered,
                        image: item.acf.awardee_image,
                        year: item.acf.awardee_year,
                        slug: item.slug,
                        award: item.awards[0],
                        // award_slug: item.awards[0].term_slug,
                    }
                });
                console.log(awardeedataloader);
                setAllawardeedetails(awardeedataloader);
                setLoader(false);
            })
            .catch((error) => {
                console.error(error);
            });
        // setAwardeeloader(false);
    }, []);

    return (
        <>
            {loader ? <Loader /> :
                <>
                    <div className='page-container'>
                        <div className='outer-section text-center'>
                            <LargeHeading title="All PPS Awardees" />
                        </div>
                        <div className='inner-section'>
                            <div className="flex flex-wrap special-margin">
                                {
                                    allawardeedetails.map(awardee => (
                                        <div className='w-1/2 md:w-1/2 lg:w-1/4 flex flex-col items-center mb-[10px]'>
                                            <AllAwardeeCard imagepath={awardee.image} name={awardee.name} year={awardee.year} slug={awardee.slug} award_name={awardee.award} key={awardee.name} />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default AllAwardees