import React from 'react'

const NewsletterSingle = ({ title, url }) => {
    return (
        <>
            {/* <div className='newsletter-card-backdrop'>

            </div> */}
            <div>
                <a href={url} target="_blank">
                    <div className='newsletter-card'>
                        <h3 className='newsletter-card-text text-[34px]'>{title}</h3>
                    </div>
                </a>
            </div>
        </>
    )
}

export default NewsletterSingle