import React from 'react'

const NewsletterSingle2 = ({ year, onClick }) => {
    return (
        <>
            <div>
                <div onClick={onClick}>
                    <div className='newsletter-card'>
                        <h3 className='newsletter-card-text text-[34px]'>{year}</h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewsletterSingle2