import React from 'react'
import Lottie from "lottie-react";
import sPinner from "../assets/spinner.json";
import "./components.css"


const loaderstyle = {
    height: 300,
};

function Loader() {
    return (
        <div>
            <div className="spinner">
                <Lottie animationData={sPinner} loop={true} style={loaderstyle} />
            </div>
        </div>
    )
}

export default Loader