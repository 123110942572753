import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/Loader';
import LargeHeading from '../components/LargeHeading';
import MediumHeading from '../components/MediumHeading';
import SmallHeading from './../components/SmallHeading';
import TextBlock from '../components/TextBlock';
import AwardeeCard from '../components/AwardeeCard';
import placeholderImg from '../assets/profile_placeholder.jpg';


const AwardeeTemplate = () => {
    const { awardeeSlug } = useParams(); // Access the parameter as 'pageSlug'
    const [awardeedata, setAwardeedata] = useState([]);
    const [otherawardees, setOtherawardees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);


    const noProfile = "https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/no_profile.png"


    useEffect(() => {
        setLoading(true);
        axios.get(`wp/v2/awardees?slug=${awardeeSlug}&acf_format=standard`)
            .then((response) => {
                // console.log(response.data[0].acf.awardee_image);
                getotherawardees(response.data[0].awards[0].term_slug, response.data[0].id);
                setAwardeedata({
                    id: response.data[0].id,
                    name: response.data[0].title.rendered,
                    about: response.data[0].content.rendered,
                    image: response.data[0].acf.awardee_image,
                    image_caption: response.data[0].acf.awardee_image_caption_if_any,
                    year: response.data[0].acf.awardee_year,
                    slug: response.data[0].slug,
                    award: response.data[0].awards[0].term_name,
                    award_slug: response.data[0].awards[0].term_slug,
                    award_id: response.data[0].awards[0].term_id,
                });
                setLoading(false);
                // setAwarddetailsloader(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [awardeeSlug]);

    // useEffect(() => {console.log(awardeedata)},[awardeedata])

    const getotherawardees = (award_slug, exclude) => {
        // console.log(award_slug, exclude);
        axios.get(`wp/v2/awardees?filter[awards]=${award_slug}&per_page=99&acf_format=standard`)
            .then((response) => {
                let initialresponse = response.data;
                // console.log(initialresponse);

                let updatedresponse = initialresponse.filter((item) => item.id !== exclude);
                // console.log(updatedresponse);

                setOtherawardees(updatedresponse);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // useEffect(() => { console.log(awardeedata) });

    return (
        <>
            {
                (loading === true) ? <Loader /> :
                    <>
                        <div className="outer-section">
                            <div>
                                {
                                    (awardeedata.image === false) ?
                                        // <img src="https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/no_profile.png" className='awardee_image_single' /> :
                                        // <img src={awardeedata.image.url} className='awardee_image_single' />
                                        <img
                                            src={require(`../assets/no_profile.png`)}
                                            onLoad={() => setLoaded(true)}
                                            alt="awardee_image"
                                            className='awardee_image_single'
                                        />
                                        :
                                        <img
                                            src={loaded ? awardeedata.image.url : placeholderImg}
                                            onLoad={() => setLoaded(true)}
                                            alt="awardee_image"
                                            className='awardee_image_single'
                                        />
                                }
                            </div>
                            <div className="inner-section text-center">
                                <p className="heading-medium pt-[10px] pb-[5px] force-color-black">{awardeedata.award}, {awardeedata.year}</p>
                                <LargeHeading title={awardeedata.name} />
                            </div>
                            <div className="inner-section">
                                <TextBlock content={awardeedata.about} />
                            </div>
                            <div className='outer-section text-center'>
                                <MediumHeading title="Other Awardees" />
                            </div>
                            <div className='inner-section'>
                                <div className="flex flex-wrap special-margin">
                                    {
                                        otherawardees.map(awardee => (
                                            <div className='w-1/2 md:w-1/2 lg:w-1/4 flex flex-col items-center mb-[10px]'>
                                                <AwardeeCard imagepath={awardee.acf.awardee_image} name={awardee.title.rendered} year={awardee.acf.awardee_year} slug={awardee.slug} key={awardee.name} />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default AwardeeTemplate