import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import LargeHeading from './../components/LargeHeading';
import AwardeeCard from '../components/AwardeeCard';
import ButtonSmall from '../components/ButtonSmall';
import Loader from '../components/Loader';
import Awardee from './Awardee';


const Awardees = () => {
    const [awarddetailsloader, setAwarddetailsloader] = useState(true);
    const [awardeeloader, setAwardeeloader] = useState(true);
    const [awardeedetails, setAwardeedetails] = useState([]);
    const [awardname, setAwardname] = useState(String);
    const { awardSlug } = useParams(); // Access the parameter as 'pageSlug'
    // console.log(awardSlug);

    const getAwardees = () => {
        axios.get(`wp/v2/awardees?filter[awards]=${awardSlug}&per_page=99&acf_format=standard`)
            .then((response) => {
                // console.log("running");
                const awardeedataloader = response.data.map(item => {
                    return {
                        name: item.title.rendered,
                        image: item.acf.awardee_image,
                        year: item.acf.awardee_year,
                        slug: item.slug
                    }
                });
                // console.log(awardeedataloader);
                setAwardeedetails(awardeedataloader);
                setAwardeeloader(false);
            })
            .catch((error) => {
                console.error(error);
            });
        // setAwardeeloader(false);
    }

    // useEffect(() => { console.log(awardeedetails)},[awardeedetails])

    const getAwarddetails = () => {
        axios.get(`wp/v2/awards?slug=${awardSlug}`)
            .then((response) => {
                setAwardname(response.data[0].name);
                setAwarddetailsloader(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        setAwarddetailsloader(true);
        setAwarddetailsloader(true);
        // console.log("new page data loaded")
        getAwardees();
        getAwarddetails();

    }, [awardSlug])

    return (
        <>
            {
                (awardeeloader === true && awarddetailsloader === true) ? <Loader /> :
                    <>
                        <div>
                            <Helmet>
                                <title>{awardname} - Polymer Processing Society</title>
                            </Helmet>
                        </div>
                        <div className='page-container'>
                            <div className='outer-section text-center'>
                                <LargeHeading title={awardname} />
                            </div>
                            <div className='inner-section'>
                                <div className="flex flex-wrap special-margin">
                                    {
                                        awardeedetails.map(awardee => (

                                            <div className='w-1/2 md:w-1/2 lg:w-1/4 flex flex-col items-center mb-[10px]'>
                                                <AwardeeCard imagepath={awardee.image} name={awardee.name} year={awardee.year} slug={awardee.slug} key={awardee.name} />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default Awardees