import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./components.css";


const AllAwardeeCard = ({ name, year, slug, imagepath, award_name }) => {

    // let a_name = award_name.term_name;
    const [awardname, setAwardname] = useState(String);


    useEffect(() => {
        if (award_name === undefined) {
            console.log("undefined award_name");
            setAwardname(" ");
        } else {
            console.log(award_name.term_name);
            setAwardname(award_name.term_name);
        }
    }, [])

    // console.log(imagepath);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling animation
        });
    }

    return (
        <div className='flex flex-col items-center mx-[5px] mt-0 mb-[20px]'>
            <>
                {
                    // (imagepath === false) ?
                    // <img src={require(`../assets/no_profile.png`)} className='awardee_image' /> :
                    // <img src={imagepath} className='awardee_image' />
                    imagepath ?
                        <img src={imagepath.sizes.medium} className='awardee_image' />
                        :
                        <img src={require(`../assets/no_profile.png`)} className='awardee_image' />
                }

            </>
            {/* <img src={imagepath} width="250" /> */}
            <p className="text-center text pt-[5px] pb-[5px]">{name}</p>
            {
                (awardname === " ") ?
                    <p className="text-center text font-bold">{year}</p> :
                    <p className="text-center text font-bold">{awardname}, {year}</p>
            }

            <Link to={`/awardees/${slug}`}>
                <button onClick={scrollToTop} className='rounded-[3px] bg-[#5C469C] px-[5px] py-[2px] flex mt-[10px] mb-[10px]'>
                    <p className='text-small-inverse'>Details</p>
                </button>
            </Link>
        </div>
    )
}

export default AllAwardeeCard