import React from 'react'
import "./components.css"

const EventTableHeader = ({ heading }) => {
    return (
        <div className="event-table-header">
            <h2 className='heading-small-inverse px-[15px] py-[9px]'>{heading}</h2>
        </div>
    )
}

export default EventTableHeader