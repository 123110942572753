import React from 'react'
import './components.css'
import './text.css'
import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";

const GutenbergBlock = ({ content }) => {
    // console.log(content);
    return (
        <div>
            <div className="text html_text_block" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    )
}


// sample gutenberg response
{/* <>

    < p > The Polymer Processing Society was founded in March 1985 at the University of Akron, Ohio, USA.The intent was to provide a mechanism and format for interaction and presentation of research results in the international polymer processing community.</p >

    <h2 class="wp-block-heading has-text-align-left">Our Goals</h2>

    <p>The goals of the&nbsp;<em>Polymer Processing Society</em>&nbsp;(PPS) as embodied in its constitution are to foster scientific understanding and technical innovation in polymer processing by providing a discussion forum for the worldwide community of engineers and scientists in the field. The thematic range of the PPS encompasses all formulation, conversion and shaping operations applied to polymeric systems in the transformation from their monomeric forms to commercial products.</p>

    <h2 class="wp-block-heading has-text-align-left">Membership</h2>

    <p>Membership in PPS is open to all researchers in the field, and to all persons who feel the activities of the society advance their professional development.</p>

    <h2 class="wp-block-heading">Constitution</h2>

    <p><a href="https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/PPS_constitution.pdf" data-type="link" data-id="https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/PPS_constitution.pdf">THE POLYMER PROCESSING SOCIETY &#8211; Constitution and Bylaws as amended at the Orlando, Florida, Annual Meeting May 8 &#8211; 11, 1988</a></p>

    <h2 class="wp-block-heading">PPS Resources: </h2>

    <figure class="wp-block-table">
        <table>
            <thead>
                <tr>
                    <th class="has-text-align-left" data-align="left">Title</th>
                    <th class="has-text-align-left" data-align="left">Modified Date</th>
                    <th class="has-text-align-left" data-align="left">Size</th>
                    <th class="has-text-align-left" data-align="left">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="has-text-align-left" data-align="left"><strong>PPS Logo &#8211; Color</strong></td>
                    <td class="has-text-align-left" data-align="left">2/13/2012</td>
                    <td class="has-text-align-left" data-align="left">31.91 KB</td>
                    <td class="has-text-align-left" data-align="left"><a href="https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/PPSlogo.jpg" data-type="link" data-id="https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/PPSlogo.jpg">Download</a>
                    </td>
                </tr>
                <tr>
                    <td class="has-text-align-left" data-align="left"><strong>PPS Logo &#8211; BW</strong></td>
                    <td class="has-text-align-left" data-align="left">2/13/2012</td>
                    <td class="has-text-align-left" data-align="left">32.10 KB</td>
                    <td class="has-text-align-left" data-align="left"><a href="https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/PPSlogoBW.jpg" data-type="link" data-id="https://pps-cms.connectedbytes.in/wp-content/uploads/2023/09/PPSlogoBW.jpg">Download</a></td>
                </tr>
            </tbody>
        </table>
    </figure>

</> */}

export default GutenbergBlock